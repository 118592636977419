import React, {Component} from "react";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import {database} from "../db/firebase";
import {initialPrintingOrderLogDetail} from "../actions/printingOrderLogDetail";
import OrderLogDetail from "../helpers/OrderLogDetail";
import { checkTokenExpire } from '../services/userService';

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

class PrintingOrderLogDetail extends Component {
    constructor(props) {
        super(props);
        let currentDate = new Date();
        const currentDateString = JSON.parse(localStorage.getItem('currentDate'));
        if(currentDateString) {
            currentDate = new Date(currentDateString);
        }

        let orderID = '';
        if(this.props.location.state && this.props.location.state.orderID ) {
            orderID = this.props.location.state.orderID;
        }
        else if(localStorage.getItem('orderID')){
            orderID = JSON.parse(localStorage.getItem('orderID'));
        }

        let requestID = '';
        if(this.props.location.state && this.props.location.state.requestID ) {
            requestID = this.props.location.state.requestID;
        }
        else if(localStorage.getItem('requestID')){
            requestID = JSON.parse(localStorage.getItem('requestID'));
        }

        this.state = {
            logDetail: [],
            orderID: orderID,
            requestID: requestID,
            ref: "order-print-logs-detail/" + dateFormat(currentDate, "yyyymmdd") + "/" + orderID,//this.props.location.state.orderID,
        };
    }

    async componentDidMount() {
        console.log('componentDidMount');
        checkTokenExpire(() => this.props.history.push('/login'));

        if(((this.props.location.state === undefined || this.props.location.state.orderID === undefined) && localStorage.getItem('orderID') === undefined)
            ||((this.props.location.state === undefined || this.props.location.state.requestID === undefined) && localStorage.getItem('requestID') === undefined) 
            ) {
            this.props.history.push('/printingOrders')
        }

        let items = await this.fetchDataFromFireBase("value", this.state.ref);
        let logDetail = items.filter(x => x.requestID === this.state.requestID);
        this.props.initialPrintingOrderLogDetail(logDetail);

        let ref = database.ref(this.state.ref);
        database.ref(ref).on("value", snapshot => {
            if(snapshot.val()){
                let data = snapshot.val();
                let dataItems = Object.values(data);
                let dataLogDetail = dataItems.filter(x => x.requestID === this.state.requestID);
                this.props.initialPrintingOrderLogDetail(dataLogDetail);
            }
        });
    }

    componentWillUnmount() {
        database.ref(this.state.ref).off("value");
    }


    async fetchDataFromFireBase(e, ref) {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();
        if (data) {
            let items = Object.values(data);
            console.log("items.length:", items.length);
            return items;
        } else {
            return [];
        }
    };

    onBackPage = () => {
        this.props.history.push({
             pathname: `/jobOrderPrintingLogs/${this.state.orderID}`,
            state: {orderID: this.state.orderID},
        });
    }

    render() {
        return (
            OrderLogDetail(this.props.logDetail, this.onBackPage)
        );
    }
}


const mapStateToProps = state => {
    return {
        logDetail: state.printingOrderLogDetailStore.logDetail,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initialPrintingOrderLogDetail: logDetail => {
            dispatch(initialPrintingOrderLogDetail(logDetail));
        },
    };
};

const JobOrderPrintingLogRx = connect(mapStateToProps, mapDispatchToProps)(
    PrintingOrderLogDetail
);
export default JobOrderPrintingLogRx;
